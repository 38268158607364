<template>
  <vue-tags-input
    v-model="tag"
    :tags="tags"
    :autocomplete-items="autocompleteItems"
    @tags-changed="onSerialNumbersUpdated"
    :placeholder="$t('vehicle-load.load-control.add-serial-number')"
    :add-on-blur="false"
  />
</template>

<script>
import VueTagsInput from "@johmun/vue-tags-input";

export default {
  components: {
    VueTagsInput
  },
  props: {
    goodsIndex: { type: Number, required: true },
    // serialNumbers and availableSerialNumbers are arrays of strings
    serialNumbers: { type: Array, required: true },
    availableSerialNumbers: { type: Array, required: true }
  },
  data() {
    return {
      tag: ""
    };
  },
  computed: {
    tags() {
      return this.serialNumbers.map(x => {
        return { text: x };
      });
    },
    autocompleteItems() {
      return this.availableSerialNumbers
        .filter(x => x.indexOf(this.tag) !== -1)
        .map(x => {
          return { text: x };
        });
    }
  },
  methods: {
    onSerialNumbersUpdated(newValue) {
      let serialNumbers = newValue.map(x => x.text);
      this.$store.commit("vehicleLoad/updateSerialNumbers", { goodsIndex: this.goodsIndex, newValue: serialNumbers });
    }
  }
};
</script>
