<template>
  <b-container fluid class="overflowable">
    <b-row>
      <b-col>
        <h2>{{ $t("vehicle-load.page-title") }}</h2>
        <vehicle-list></vehicle-list>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VehicleList from "@/components/vehicle-load/VehicleList.vue";

export default {
  components: {
    "vehicle-list": VehicleList
  }
};
</script>
