<template>
  <div>
    <vehicle-table :items="vehicles"></vehicle-table>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import VehicleTable from "./VehicleTable.vue";
import { UPDATE_VEHICLE_LOAD_LIST } from "@/const/events.js";

export default {
  components: {
    "vehicle-table": VehicleTable
  },
  data() {
    return {
      vehicles: []
    };
  },
  methods: {
    ...mapActions(["vehicleLoad/getVehicles"]),
    updateVehicleList() {
      this["vehicleLoad/getVehicles"]().then(resp => {
        this.vehicles = resp.data;
      });
    }
  },
  mounted() {
    this.updateVehicleList();
    this.$bus.on(UPDATE_VEHICLE_LOAD_LIST, this.updateVehicleList);
  },
  beforeDestroy() {
    this.$bus.off(UPDATE_VEHICLE_LOAD_LIST, this.updateVehicleList);
  }
};
</script>
