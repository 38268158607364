<style>
.vs__dropdown-menu {
  position: static;
}
</style>
<template>
  <div>
    <b-button @click="onAddLoadItemClick">{{ $t("vehicle-load.load-control.add-btn-caption") }}</b-button>
    <b-row>
      <b-col md="6" class="my-3">
        <v-select
          :value="selectedGroup"
          :options="groupOptions"
          :getOptionLabel="getGroupOptionLabel"
          @input="onGoodsGroupSelected($event)"
          :placeholder="$t('vehicle-load.select-group-placeholder')"
        >
          <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
        </v-select>
      </b-col>
    </b-row>
    <b-input
      class="mb-2"
      v-model="filters.goodsTitle"
      :placeholder="$t('vehicle-load.load-control.filters.goods-title')"
    ></b-input>
    <b-table :fields="fields" :items="items" :tbody-tr-class="getRowClass" responsive small>
      <template v-slot:cell(thumbnailImage)="row">
        <thumbnail-image :imageId="row.item.goods.imageId" />
      </template>
      <template v-slot:cell(goodsTitle)="data">
        <v-select
          :clearable="false"
          :value="data.item.goods"
          :options="options"
          :getOptionLabel="getOptionLabel"
          @input="onGoodsTitleChanged(data.index, $event)"
        >
          <template v-slot:no-options>{{ $t("vselect.noOptions") }}</template>
        </v-select>
      </template>
      <template v-slot:cell(count)="data">
        <b-input
          :value="data.item.count"
          @input="onCountUpdated(data.index, $event)"
          @keypress="isNumber($event)"
        ></b-input>
      </template>
      <template v-slot:cell(serialNumber)="data">
        <div v-if="data.item.goods.hasSerialNumber && data.item.count">
          <serial-numbers
            :serialNumbers="data.item.serialNumbers"
            :availableSerialNumbers="availableSerialNumbersForProduct(data.item.goods.id)"
            :goodsIndex="data.index"
          ></serial-numbers>
        </div>
      </template>
      <template v-slot:cell(actions)="data">
        <b-button variant="outline-danger" @click="onRemoveGoodsClick(data.index)">
          {{ $t("vehicle-load.load-control.remove-btn-caption") }}
        </b-button>
      </template>
    </b-table>
    <image-modal />
  </div>
</template>

<script>
import SerialNumbers from "./SerialNumbers.vue";
import ImageModal from "@/components/goods-images/ImageModal.vue";
import ThumbnailImage from "@/components/goods-images/ThumbnailImage.vue";

import stringComparator from "@/services/string-comparator.js";

export default {
  components: {
    SerialNumbers,
    "image-modal": ImageModal,
    "thumbnail-image": ThumbnailImage
  },
  data() {
    return {
      selectedGroup: null,
      filters: {
        goodsTitle: ""
      }
    };
  },
  computed: {
    items() {
      return this.$store.state.vehicleLoad.loadItems;
    },
    fields() {
      return [
        { key: "thumbnailImage", label: "" },
        { key: "goodsTitle", label: this.$t("vehicle-load.load-control.field.goods-title") },
        { key: "count", label: this.$t("vehicle-load.load-control.field.count") },
        { key: "minQuantity", label: this.$t("vehicle-load.load-control.field.min-quantity") },
        { key: "quantity", label: this.$t("vehicle-load.load-control.field.quantity") },
        { key: "unit", label: this.$t("vehicle-load.load-control.field.unit") },
        { key: "serialNumber", label: this.$t("vehicle-load.load-control.field.serial-number") },
        { key: "actions", label: "" }
      ];
    },
    options() {
      let alreadySelectedGoodsIds = this.items.filter(x => x.goods.id !== 0).map(x => x.goods.id);
      return this.$store.state.vehicleLoad.availableGoods.filter(x => !alreadySelectedGoodsIds.includes(x.id));
    },
    groupOptions() {
      return this.$store.state.goodsGroups;
    }
  },
  methods: {
    getRowClass(item) {
      if (
        item &&
        item.goods.code &&
        !stringComparator.ciIncludes(this.getOptionLabel(item.goods), this.filters.goodsTitle)
      ) {
        return "d-none";
      }

      return "";
    },
    availableSerialNumbersForProduct(goodsInfoId) {
      return this.$store.state.vehicleLoad.goodsSerialNumbers.find(x => x.goodsInfoId == goodsInfoId).serialNumbers;
    },
    getOptionLabel(value) {
      if (value.id === 0) return "";
      return `${value.title[this.$i18n.locale]} - ${value.code}`;
    },
    getGroupOptionLabel(value) {
      if (value !== null) return `${value.title[this.$i18n.locale]}`;
      else return null;
    },
    onAddLoadItemClick() {
      this.$store.commit("vehicleLoad/addEmptyItem");
    },
    onGoodsTitleChanged(currentItemIndex, newItem) {
      const goodsDesc = this.$store.state.vehicleLoad.availableGoods.find(x => x.id == newItem.id);
      const newGoods = {
        id: goodsDesc.id,
        title: goodsDesc.title,
        hasSerialNumber: goodsDesc.hasSerialNumber,
        code: goodsDesc.code,
        imageId: goodsDesc.imageId
      };
      this.$store.commit("vehicleLoad/updateGoodsTitle", { oldGoodsIndex: currentItemIndex, newGoods: newGoods });
    },
    onRemoveGoodsClick(goodsIndex) {
      this.$store.commit("vehicleLoad/removeGoods", goodsIndex);
    },
    onCountUpdated(goodsIndex, newValue) {
      const newValueParsed = parseFloat(newValue);
      if (isNaN(newValueParsed)) {
        this.$store.commit("vehicleLoad/updateCount", { goodsIndex, newValue: 0 });
      } else {
        this.$store.commit("vehicleLoad/updateCount", { goodsIndex, newValue: newValueParsed });
      }
    },
    onGoodsGroupSelected(newValue) {
      this.selectedGroup = newValue;
      if (newValue !== null)
        this.$store.dispatch("vehicleLoad/updateGoodsGroupTitle", {
          newValue: newValue.title[this.$i18n.locale],
          locale: this.$i18n.locale
        });
      else
        this.$store.dispatch("vehicleLoad/updateGoodsGroupTitle", {
          newValue: null,
          locale: this.$i18n.locale
        });
    },
    isNumber: function(evt) {
      // digits and dots
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    filterOptions(options, search) {
      let searchValue = search.toLowerCase();

      return options.filter(
        x => x.title.toLowerCase().includes(searchValue) || x.barcode.toLowerCase().includes(searchValue)
      );
    }
  }
};
</script>
