<template>
  <div>
    <b-table
      :fields="fields"
      :items="tableRowFilter"
      caption-top
      :caption="$t('vehicle-load.vehicle-table.caption')"
      show-empty
      :emptyText="$t('vehicle-load.vehicle-table.empty-text')"
      responsive
    >
      <template v-slot:top-row>
        <td>
          <b-input v-model.trim="tableFilter.registrationNumber"></b-input>
        </td>
        <td>
          <b-input v-model.trim="tableFilter.type"></b-input>
        </td>
        <td>
          <b-input v-model.trim="tableFilter.category"></b-input>
        </td>
        <td>
          <b-input v-model.trim="tableFilter.template"></b-input>
        </td>
        <td>
          <b-input v-model.trim="tableFilter.lastLoadedAt"></b-input>
        </td>
        <td>
          <b-input v-model.trim="tableFilter.lastLoadedBy"></b-input>
        </td>
      </template>
      <template v-slot:cell(lastLoadedAt)="row">
        {{ row.item.lastLoadedAt == null ? "" : $d(Date.parse(row.item.lastLoadedAt)) }}
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          v-if="row.item.lastLoadedAt"
          @click="$router.push({ name: 'current-load', params: { vehicleId: row.item.id } })"
        >
          {{ $t("vehicle-load.vehicle-table.unload-btn-caption") }}
        </b-button>
        <b-button v-else @click="onLoadVehicleClick(row.item)">
          {{ $t("vehicle-load.vehicle-table.load-btn-caption") }}
        </b-button>
      </template>
      <template v-slot:cell(type)="row">{{ row.value.title[$i18n.locale] }}</template>
      <template v-slot:cell(category)="row">{{ row.value.title[$i18n.locale] }}</template>
    </b-table>
    <b-modal
      id="load-vehicle-modal"
      size="xl"
      :ok-title="$t('vehicle-load.modal.ok-caption')"
      :cancel-title="$t('vehicle-load.modal.cancel-caption')"
      :title="$t('vehicle-load.modal.title')"
      @ok="onVehicleLoadedClick"
    >
      <template v-slot:default>
        <vehicle-load-control></vehicle-load-control>
      </template>
    </b-modal>
  </div>
</template>

<script>
import VehicleLoadControl from "./VehicleLoadControl.vue";
import { mapActions } from "vuex";
import { UPDATE_VEHICLE_LOAD_LIST } from "@/const/events.js";
import comparator from "@/services/string-comparator.js";
import date from "@/services/date.js";

export default {
  components: {
    "vehicle-load-control": VehicleLoadControl
  },
  props: {
    items: { type: Array, required: true }
  },
  data() {
    return {
      tableFilter: {
        registrationNumber: "",
        type: "",
        category: "",
        template: "",
        lastLoadedAt: "",
        lastLoadedBy: ""
      }
    };
  },
  computed: {
    fields() {
      return [
        {
          key: "registrationNumber",
          label: this.$t("vehicle-load.vehicle-table.field.registration-number"),
          sortable: true
        },
        { key: "type", label: this.$t("vehicle-load.vehicle-table.field.type"), sortable: true },
        { key: "category", label: this.$t("vehicle-load.vehicle-table.field.category"), sortable: true },
        { key: "template", label: this.$t("vehicle-load.vehicle-table.field.template"), sortable: true },
        { key: "lastLoadedAt", label: this.$t("vehicle-load.vehicle-table.field.loadDate"), sortable: true },
        { key: "lastLoadedBy", label: this.$t("vehicle-load.vehicle-table.field.user"), sortable: true },
        { key: "actions", label: this.$t("vehicle-load.vehicle-table.field.actions") }
      ];
    },
    tableRowFilter() {
      let filteredItems = this.items.filter(
        x =>
          comparator.compareRows(x.registrationNumber, this.tableFilter.registrationNumber) &&
          (x.type != null ? comparator.compareRows(x.type.title[this.$i18n.locale], this.tableFilter.type) : true) &&
          comparator.compareRows(x.category.title[this.$i18n.locale], this.tableFilter.category) &&
          (x.template != null ? comparator.compareRows(x.template, this.tableFilter.template) : true) &&
          date.getStartOfDayToISOString(x.lastLoadedAt).includes(this.tableFilter.lastLoadedAt) &&
          (x.lastLoadedBy != null ? comparator.compareRows(x.lastLoadedBy, this.tableFilter.lastLoadedBy) : true)
      );
      return filteredItems;
    }
  },
  methods: {
    ...mapActions(["vehicleLoad/setInitialStateForVehicle", "vehicleLoad/uploadVehicleLoad"]),
    onLoadVehicleClick(vehicle) {
      // load necessary data
      this["vehicleLoad/setInitialStateForVehicle"](vehicle.id).then(() =>
        // show load control
        this.$bvModal.show("load-vehicle-modal")
      );
    },
    onVehicleLoadedClick(evt) {
      evt.preventDefault();
      var validationError = this.$store.getters["vehicleLoad/firstValidationErrorCode"];
      if (validationError != null) {
        alert(this.$t("vehicle-load.error." + validationError));
      } else {
        // upload results
        this["vehicleLoad/uploadVehicleLoad"]()
          .then(() => {
            this.$bvModal.hide("load-vehicle-modal");
            this.$bus.emit(UPDATE_VEHICLE_LOAD_LIST);
          })
          .catch(err => {
            if (err.data.errorCode) {
              alert(this.$t("vehicle-load.error." + err.data.errorCode, err.data.additionalData));
            } else {
              alert(this.$t("vehicle-load.error.error-while-uploading-vehicle-load"));
            }
          });
      }
    }
  }
};
</script>
